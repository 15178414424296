<template>
    <div class="delete-ticket">
        <div class="popup">
            <div class="title">assign ticket</div>
            <div class="message">
                <span class="warning">
                    Assign a user to this ticket:
                </span>
                <div class="ticket">
                    <div class="color" :class="[ticket.ticket_priority.priority_details.name.toLowerCase()]"></div>
                    <div class="info">
                        <div class="name">{{ticket.title}}</div>
                        <div class="serial">{{ticket.product.name}} #{{ticket.product.serial_no}}</div>
                        <div class="client">unknown</div>
                        <div class="priority">
                            <div class="current" @click="openPriorities = ticket.id">
                                <div class="prio">
                                    <img :src="ticket.ticket_priority.priority_details.id === 3 ? '/assets/images/high_flag.svg' : ticket.ticket_priority.priority_details.id === 2 ? '/assets/images/medium_flag.svg' : '/assets/images/low_flag.svg'" alt="">
                                    <span :class="`text ${ticket.ticket_priority.priority_details.name.toLowerCase()}`">{{ ticket.ticket_priority.priority_details.name }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="assignee" @click="assignTo = ticket.id">
                            <div v-if="ticket.assigned_users.length" class="assigned">
                                <img v-if="ticket.assigned_users.length && ticket.assigned_users[0].avatar" :src="imgURL + ticket.assigned_users[0].avatar" :title="ticket.assigned_users[0].name">
                                <span v-else class="noimg">{{ticket.assigned_users[0].name.charAt(0)}}</span>
                            </div>
                            <div v-else>
                                not assigned
                            </div>
                        </div>
                    </div>
                </div>
                <hr>
                <div class="assign">
                    <div class="multiple-container">
                        <input type="text" :placeholder="`Search user here`" v-model="search_key" @click="multipleShowAll = true" class="type-text">
                        <div v-if="multipleShowAll" class="all-items" v-click-outside="outside">
                            <div class="multiple-search" v-for="(element,index) in filteredUsers" :key="index" @click="addMultiple(element, index)">
                                <div class="item-info">
                                    <span v-if="element.image" class="img"><img :src="element.image" alt=""></span>
                                    <span v-else class="no-img">{{element.name.charAt(0)}}</span>
                                    <span class="text">{{element.name}}</span>
                                </div>
                                <span class="add-item">+</span>
                            </div>
                            <div v-if="!filteredUsers.length">No results found</div>
                        </div>
                        <div class="selected-items">
                            <div class="added-item" v-for="(element, index) in selects" :key="index" @click="removeMultiple(element, index)">
                                <div class="item-info" >
                                    <span v-if="element.image" class="img"><img :src="element.image" alt=""></span>
                                    <span v-else class="no-img">{{element.name.charAt(0)}}</span>
                                </div>
                                <span class="remove-item" :title="element.name">x</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="buttons">
                    <span @click="$emit('close', selects)">confirm</span>
                    <span @click="$emit('close', 0)">cancel</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        ticket: {
            required: true,
            type: Object,
            default: () => {}
        },
        users: {
            required: true,
            type: Array,
            default: () => {}
        },
    },

    data() {
        return {
            search_key: '',
            multipleShowAll: false,
            selects: [],
            filteredUsers: this.users,
            imgURL: process.env.VUE_APP_DO_SPACES,
        }
    },

    watch: {
        users(val) {
            this.filteredUsers = val
        },
        search_key(val) {
            this.searchMultiple(val)
        }
    },

    methods: {
        outside() {
            this.multipleShowAll = false
        },

        searchMultiple(data) {
            if (data.length) this.filteredUsers = this.filteredUsers.filter(el => el.name.toLowerCase().includes(data.toLowerCase()))
            else this.filteredUsers = this.users
        },

        addMultiple(item, index) {
            this.filteredUsers.splice(index, 1)
            this.selects.push(item)
        },

        removeMultiple(item, index) {
            this.selects.splice(index,1)
            this.filteredUsers.push(item)
        }
    },
}
</script>

<style lang="scss" scoped>
.delete-ticket {
    position: fixed;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.2);
    top: 0;
    left: 0;
    z-index: 4;
    display: flex;
    justify-content: center;
    align-items: center;
    .popup{
        border-radius: 5px;
        background: white;
        width: 400px;
        box-shadow: 0px 1px 6px #CAD6E280;
        border-bottom: solid 1px #F2F5F8;
        .title {
            box-shadow: 0px 1px 6px #CAD6E280;
            border-bottom: solid 1px #F2F5F8;
            padding: 20px;
            text-transform: uppercase;
            color: #FB9A4B;
            font-weight: bold;
            font-size: 1.2rem;
        }
        .message {
            padding: 20px;
            display: flex;
            flex-direction: column;
            gap: 20px;
            .warning {
                font-size: 1.4rem;
                font-weight: bold;
            }
            .ticket {
                width: 100%;
                display: flex;
                cursor: pointer;
                user-select: none;
                min-height: 100px;
                &.drag {
                    opacity: 0.5;
                }

                .color{
                    width: 2%;
                    border-top-left-radius: 5px;
                    border-bottom-left-radius: 5px;
                    &.high {
                        background: #ED0423;
                    }
                    &.medium {
                        background: #FB9A4B;
                        }
                    &.low {
                        background: #CAD6E2;
                    }
                }
                .text{
                    &.high {
                        color: #ED0423;
                    }
                    &.medium {
                        color: #FB9A4B;
                        }
                    &.low {
                        color: #CAD6E2;
                    }
                }
                .info{
                    width: 98%;
                    padding: 10px;
                    border-top-right-radius: 5px;
                    border-bottom-right-radius: 5px;
                    box-shadow: 0px 1px 6px #CAD6E280;
                    position: relative;
                    .name {
                        font-weight: bold;
                    }
                    .serial {
                        font-weight: 500;
                        font-size: 0.8rem;
                    }
                    .client {
                        color: #CAD6E2;
                        font-size: 0.8rem;
                    }
                    .assignee {
                        position: absolute;
                        bottom: 10px;
                        right: 10px;
                        display: flex;
                        gap: 5px;
                        .assigned{
                            width: 20px;
                            height: 20px;
                            border-radius: 50%;
                            overflow: hidden;
                            img{
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                            }
                        }

                        .arrow {
                            width: 10px;
                            transform: rotate(270deg);
                        }
                        .noimg{
                            width: 100%;
                            height: 100%;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            font-weight: bold;
                            text-transform: uppercase;
                            background: var(--primary-color);
                            color: white;
                        }
                    }

                    .priority {
                        margin-top: 10px;
                        position: relative;
                        .current {
                            display: flex;
                            gap: 20px;
                            .prio {
                                display: flex;
                                gap: 10px;
                            }
                        }
                    }
                }
            }

            .multiple-container {
                width: 100%;
                position: relative;
                display: flex;
                flex-direction: column;
                gap: 20px;
                input {
                    width: 100%;
                    border: solid 1px #CAD6E2;
                    padding: 10px;
                    border-radius: 5px;
                }
                .all-items {
                    width: 100%;
                    position: absolute;
                    top: 40px;
                    background: white;
                    max-height: 300px;
                    overflow: auto;
                    z-index: 4;

                    .multiple-search {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        border-bottom: solid 1px #CAD6E2;
                        padding: 10px;
                        user-select: none;
                        cursor: pointer;
                        transition: 0.2s;

                        &:hover {
                            background: #0072FF48;
                            .add-item {
                                font-weight: 500;
                            }
                        }

                        .item-info {
                            display: flex;
                            align-items: center;
                            gap: 10px;
                            width: 90%;
                            .img {
                                width: 30px;
                                height: 30px;
                                border-radius: 50%;
                                overflow: hidden;
                                img {
                                    width: 100%;
                                    height: 100%;
                                    object-fit: cover;
                                }
                            }

                            .no-img {
                                width: 30px;
                                height: 30px;
                                border-radius: 50%;
                                overflow: hidden;
                                background: var(--primary-color);
                                color: white;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                            }
                            .text {
                                font-weight: 500;
                            }
                        }

                        .add-item {
                            font-size: 1.4rem;
                            transition: 0.2s;
                        }
                    }
                }

                .selected-items {
                    display: flex;
                    width: 100%;
                    gap: 20px;
                    flex-wrap: wrap;
                    .added-item {
                        width: calc(20% - 20px);
                        position: relative;

                        &:hover {
                            .remove-item{
                                opacity: 1;
                            }
                        }
                        .item-info {
                            width: 100%;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            .img{
                                width: 50px;
                                height: 50px;
                                border-radius: 50%;
                                overflow: hidden;
                                img {
                                    width: 100%;
                                    height: 100%;
                                    object-fit: cover;
                                }
                            }

                            .no-img{
                                width: 50px;
                                height: 50px;
                                border-radius: 50%;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                background: var(--primary-color);
                                color: white;
                                font-weight: 500;
                            }
                        }

                        .remove-item {
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            border-radius: 50%;
                            background: #cad6e273;
                            color: #ED0423;
                            opacity: 0;
                            user-select: none;
                            cursor: pointer;
                            transition: 0.2s;
                            font-weight: 500;
                            font-size: 1.2rem;
                        }
                    }
                }
            }

            .buttons{
                display: flex;
                justify-content: center;
                gap: 10px;
                span{
                    background: #F2F5F8;
                    box-shadow: 0px 1px 3px #00000033;
                    text-align: center;
                    padding: 10px 50px;
                    color: var(--primary-color);
                    user-select: none;
                    cursor: pointer;
                    border-radius: 30px;
                    transition: 0.2s;

                    &:hover {
                        background: #dee0e2;
                    }
                }
            }
        }
    }
}

</style>