<template>
    <div class="dropdown-options">
        <div class="option" v-for="(option, index) in options" :key="index" @click="handleEmit(option)">
           <span>{{option}}</span>
        </div>
        <div v-if="!noDel" class="option delete" @click="handleEmitDelete">
          <span>delete</span>
        </div>
    </div>
</template>

<script>
export default {
    props:{
        options: Array,
        noDel: {
            type: Boolean,
            required: false,
            default: () => false
        }
    },

    methods: {
        handleEmit(option) {
            this.$emit(`${option.split(' ').join('')}`)
            this.$emit(`handleAction`, option)
        },
        handleEmitDelete() {
            this.$emit('delete')
            this.$emit(`handleAction`, 'delete')
        }
    }
}
</script>

<style lang="css" scoped>
.dropdown-options {
    display: flex;
    flex-direction: column;
    position: absolute;
    right: 0;
    border-radius: 10px;
    box-shadow: 0px 1px 8px #142E6E1A;
    z-index: 1;
}

.dropdown-options .option {
    border-bottom: 1px solid #0510244D;
	padding: 10px 20px;
    background: white;
    text-transform: capitalize;
    width: 200px;
    transition: 0.2s;
    color: black;
}
.dropdown-options .option:hover {
    color: var(--primary-color);
}

.dropdown-options .delete {
    color: #FB4B5A;
}
.dropdown-options .delete:hover {
    background: #FB4B5A;
    color: white;
}

.dropdown-options .option:first-of-type {
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
}

.dropdown-options .option:last-of-type {
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    border: none;
}
</style>