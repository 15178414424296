const timeSince = {
    methods: {
        timeSince(data) {
            const last_updated = Date.parse(data)
            const seconds = Math.floor((new Date() - last_updated) / 1000);
            let interval = seconds / 31536000;
            if (interval > 1) {
                return `at ${data.split('T')[0]}`;
            }
            interval = seconds / 2592000;
            if (interval > 1) {
                return `at ${data.split('T')[0]}`;
            }
            interval = seconds / 86400;
            if (interval > 1) {
                if (Math.floor(interval) > 5) return `at ${data.split('T')[0]}`;
                return `${Math.floor(interval)} ${Math.floor(interval) === 1 ? 'day ago' : 'days ago'}`;
            }
            interval = seconds / 3600;
            if (interval > 1) {
                return `${Math.floor(interval)} ${Math.floor(interval) === 1 ? 'hour ago' : 'hours ago'}`;
            }
            interval = seconds / 60;
            if (interval > 1) {
                return `${Math.floor(interval)} ${Math.floor(interval) === 1 ? 'minute ago' : 'minutes ago'}`;
            }
            return `${Math.floor(seconds)} ${Math.floor(seconds) === 1 ? 'second ago' : 'seconds ago'}`;
        },
    },
  };

  export default timeSince;
